@import "./variables.scss";

@mixin color-settings($color) {

  .site-title { color: $color; }

    nav .link{
      border-bottom: 2px solid $color;

      &-active {
        border-bottom: 0px;
        pointer-events: none;
        color: $color;
      }

      &:hover{ 
        border-bottom: 4px solid $color; 
        color: $white;
      }
    }

    .link:hover { color: $color; }

    .button { 
      background-color: $color;

      &:hover {
        background-color: darken($color,10%);
      }
     }

    .icon path { fill: $color; }

    .project .content-heading .year {
      color: $color;
    }

    .pill {
      background-color: $color;
    }

    .info {
      background-color: rgba($color,0.2);

      p {
        background-color: $color;
      }

      @media screen and (min-width: $desktopWidth){
        background-color: $color;
      }

      &.link {
        color: $white;
      }
    }
}

.app {
  min-height: 100vh;
  font-family: $bodyFont;
  color: white;

  &-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: $spacingMd;
    z-index: 999;
    user-select: none;
    margin-top: 0;

    @media screen and (min-width: $mobileWidth){
      padding: $spacingMd $spacingLg;
    }

    .site-title {
      display: inline-block;
      font-size: $fontSizeLg;
      font-family: $headingFont;
      text-decoration: none;
      user-select: none;
      background-color: #0c0c0e;
      padding: 0px $spacingSm;
      z-index:999;
    }

    nav {
      z-index: 999;

      .link {
        display: inline-block;
        margin: 0px $spacingMd;
        color: $white;
        margin-bottom: 2px;
        background-color: $darkGray;
        text-decoration: none;
        cursor: pointer;
        &:hover{
          margin-bottom: 0px;
        }
      }
    }
  }

  &.blue-green {
    @include color-settings($blueGreen);
    .pill, .info.link, .button {
      color: $darkGray;
    }
  }

  &.red {
    @include color-settings($red);
  }

  &.orange{
    @include color-settings($orange);
  }

  &.blue {
    @include color-settings($blue);
  }

  &.purple{
    @include color-settings($purple);
  }

  &.yellow {
    @include color-settings($yellow);

    .pill, .info.link, .button {
      color: $darkGray;
    }
  }
}