@import "./variables.scss";

.about {

  .content-box {
    max-width: 600px;
    margin: $spacingXL auto;
    user-select: none;
    line-height: 24px;
    
    @media screen and (min-width: $desktopWidth){
      max-width: 700px;
    }

    .skills {
      h4 {
        text-transform: capitalize;
        margin: $spacingLg 0px $spacingSm 0px;
      }
    }
    .cat-gif {
      display: none;
      @media screen and (min-width: $tabletWidth){
        display: block;
      }
      iframe {
        margin-top: $spacing2XL;
        max-width: 100%;
      }
      
    }
    .pinky-gif {
      display: block;
      max-width: 100%;
      margin-top: $spacingXL;
      @media screen and (min-width: $tabletWidth){
        display: none;
      }
    }
  }
  .picture {
    display: none;
    width: 300px;
    position: fixed;
    z-index: 999;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    &-top {
      top: -50px;
      right: -50px;
      @media screen and (min-width: $desktopWidth){
        right: 50px;
      }
    }

    &-bottom {
      bottom: -50px;
      left: -80px;
      @media screen and (min-width: $desktopWidth){
        left: -50px;
      }
    }
    @media screen and (min-width: $tabletWidth){
      display: block;
    }
  }
}