@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap&family=Raleway:wght@300&500&display=swap');

$darkGray: #0c0c0e;
$white: rgb(255, 247, 247);
$darkGreen: #00171a;
$blueGreen: #2eb1bd;
$red: #d13333;
$purple: #a1258b;
$orange: #db5d23;
$yellow: #e9d735;
$blue: #2554d3;

$fontSizeSm: 12px;
$fontSizeMd: 16px;
$fontSizeLg: 24px;
$fontSizeXL: 32px;
$fontSize2XL: 48px;

$iconSize: 18px;

$spacingXS: 3px;
$spacingSm: 5px;
$spacingMd: 10px;
$spacingLg: 20px;
$spacingXL: 25px;
$spacing2XL: 35px;

$headingFont: 'Poppins', "Helvetica", "Arial";
$bodyFont: "Raleway", "Helvetica";

$mobileWidth: 450px;
$mobileMaxWidth: 600px;
$tabletWidth: 900px;
$desktopWidth: 1200px;
$wideScreenWidth: 1500px;
