@import "./variables.scss";

.content-box.project {
  flex-basis: 100%;
  width: 100%;
  margin-top: $spacing2XL;

  @media screen and (min-width: $tabletWidth) {
    flex-basis: calc(48.5%);
    width: 48.5%;
    margin-top: 5%;

    &:not(:nth-child(2n)){
      margin-right: 3%;
    }
  }

  .content-heading {
    font-size: $fontSizeMd;

    @media screen and (min-width: $mobileWidth) {
      font-size: $fontSizeLg;
    }
  }

  .details {
    .pills-wrap {
      margin-bottom: $spacingXL;
    }

    .link.apis:not(:first-of-type) {
      display: block;
      padding-left: $iconSize + $spacingSm;
    }
  }

  .screenshot-container {
    min-height: 300px;

    @media screen and (min-width: $desktopWidth) {
      min-height: 400px;
    }

    .screenshot {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%; 
      margin: 0px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: cover;
      min-height: inherit;
      pointer-events: all;

      @media screen and (min-width: $mobileWidth) {
        left: auto;
        margin: 0px (-$spacing2XL);
      }

      &:hover .info {
        opacity: 0.9;
      }
    
      .info {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: 0.3s;

        @media screen and (min-width: $desktopWidth) {
          opacity: 0;

        }

        p {
          padding: 1px;
          max-width: 400px;
          margin: 0px $spacing2XL;

          @media screen and (min-width: $desktopWidth) {
            margin: $spacing2XL;
          }
        }
      }
    }
  }

  ul li {
    display: block;
  }
  
}