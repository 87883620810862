@import "./variables.scss";

.drawing-canvas {
  width: 100%;

  .browser-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .side-bar {
    position: absolute;
    top: 50%;
    left: $spacingXL;
    transform: translateY(-50%);
    background-color: rgba($darkGray, 0.7);
    padding: $spacingSm;

    .line-cap {
      display: block;
      width: 28px;
      height: 28px;
      background-color: $red;
      margin-bottom: $spacingMd;
      border: 7px solid $darkGray;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transform: scale(1.3);
      }

      &.round {
        border-radius: 50%;
      }

      &.butt {
        width: 3px;
        height: 15px;
        transform: rotate(45deg);
        margin-left: 5px;
        box-sizing: content-box;

        &:hover {
          transform: rotate(45deg) scale(1.3);
        }
      }

    }
  }

  .bottom-bar {
    position: absolute;
    bottom: $spacingLg;
    width: 100%;
    z-index: 999;
    pointer-events: none;

    .button {
      position: absolute;
      bottom: 0px;
      left: auto;
      right: $spacingLg;
      width: 45px;
      z-index: 9999;
      pointer-events: all;
      transition: 0.3s;

      @media screen and (min-width: $mobileWidth){
        left: $spacingLg;
      }

      @media screen and (min-width: $tabletWidth){
        width: 240px;
      }

      &-icon {
        margin-right: $spacingSm;
      }
    }

    .color-picker {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      position: absolute;
      left: $spacingMd;
      bottom: 0px;
      background-color: rgba($darkGray, 0.7);
      padding: $spacingSm;

      @media screen and (min-width: $mobileWidth){
        left: 50%;
        transform: translateX(-50%);
      }

      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        border: 7px solid $darkGray;
        border-radius: 50%;
        margin: 0px $spacingSm;
        cursor: pointer;
        pointer-events: all;
        transition: 0.3s;
      
        &:hover {
          transform: scale(1.3);
        }
      }
    }
  }
  
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}