@import "./variables.scss";

* {
  margin: 0px;
  box-sizing: border-box;
}

html, body {
  background-color: $darkGray;
}

.button {
  padding: $spacingSm $spacingMd;
  outline: none;
  border: 3px solid $darkGray;
  //border-radius: $spacingSm;
  color: $white;
  font-family: $headingFont;
  user-select: none;
  cursor: pointer;
}

.main-content {
  max-width: $wideScreenWidth;
  margin: $spacingMd auto;
  padding: $spacingMd;
  
  @media screen and (min-width: $mobileWidth){
    padding: 0 $spacingLg;
  }

  @media screen and (min-width: $tabletWidth) {
    margin: $spacingXL auto;
  }
}

.flex {
  display: flex;
  flex-wrap: nowrap;
}

.content-box {
  width: 100%;
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  padding: $spacing2XL $spacingMd;
  position: relative;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  
  @media screen and (min-width: $mobileWidth){
    border: 1px solid $white;
    padding: $spacing2XL;
  }

  .content-heading {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $darkGray;
    padding: 0px $spacingSm;
    font-size: $fontSizeLg;
    min-width: max-content;
  }

  h3.sub-heading {
    font-size: $fontSizeLg;
    margin-top: $spacingXL;
  }

  .content-img {
    max-width: 100%;
    margin: $spacingXL 0px;
  }

  ul {
    list-style: none;
    padding: 0px;

    li {
      display: inline-flex;
      align-items: center;
      margin-top: $spacingMd;
      margin-right: $spacingXL;

      .icon {
        margin-right: $spacingSm;
        font-size: $iconSize;
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.image-zoom {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 10px solid $darkGray;
  width: 90%;
  z-index: 99999;
  cursor: zoom-out;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.link {
  color: $white;
  text-decoration: none;
}

.pills-wrap {
  display: flex;
  flex-wrap: wrap;

  .pill {
    margin-bottom: $spacingMd;
  }
}

.pill {
  padding: 1px;
  margin-right: $spacingMd;
  user-select: none;
}

.text-center {
  text-align: center;
  margin: 0px auto;
}